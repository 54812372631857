// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import Card from 'antd/lib/card';
import Empty from 'antd/lib/empty';
import Descriptions from 'antd/lib/descriptions';
import {MoreOutlined} from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
// eslint-disable-next-line import/no-extraneous-dependencies
import {MenuInfo} from 'rc-menu/lib/interface';
import {useSelector} from 'react-redux';

import {useCardHeightHOC} from 'utils/hooks';
import {CombinedState} from "../../reducers/interfaces";

const useCardHeight = useCardHeightHOC({
    containerClassName: 'cvat-jobs-page',
    siblingClassNames: ['cvat-jobs-page-pagination', 'cvat-jobs-page-top-bar'],
    paddings: 40,
    numberOfRows: 3,
});

interface Props {
    job: any;
    preview: string;
}

function JobCardComponent(props: Props): JSX.Element {
    const {job, preview} = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const history = useHistory();
    const height = useCardHeight();
    const onClick = (event: React.MouseEvent): void => {
        const url = `/tasks/${job.taskId}/jobs/${job.id}`;
        if (event.ctrlKey) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(url, '_blank', 'noopener noreferrer');
        } else {
            history.push(url);
        }
    };

    const [taskname, setTaskname] = useState("...");

    const organisation = useSelector((state: CombinedState) => state.organizations.current);
    useEffect(() => {
        var xhr = new XMLHttpRequest();
        console.log(organisation);
        xhr.open(
            'GET',
            new URL("https://cvat.tso.ukdd.de/api/tasks?filter=%7B%22%3D%3D%22%3A%5B%7B%22var%22%3A%22id%22%7D%2C" +
                job.taskId + "%5D%7D&org_id=" + organisation.id).toString(),
        );
        xhr.onload = (e) => {
            if (xhr.status <= 300) {
                let name = JSON.parse(xhr.responseText)['results'][0]['name'];
                setTaskname(name ? name : "");
            } else {
                setTaskname("");
            }
        };
        xhr.send(null);
    }, []);

    return (
        <Card
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
            style={{height}}
            className='cvat-job-page-list-item'
            cover={(
                <>
                    {preview ? (
                        <img
                            className='cvat-jobs-page-job-item-card-preview'
                            src={preview}
                            alt='Preview'
                            onClick={onClick}
                            aria-hidden
                        />
                    ) : (
                        <div className='cvat-jobs-page-job-item-card-preview' onClick={onClick} aria-hidden>
                            <Empty description='Preview not found'/>
                        </div>
                    )}
                    <div className='cvat-job-page-list-item-id'>
                        Job-ID:
                        {` ${job.id}`}
                        <br/>
                        Task:
                        {` ${taskname}`}
                    </div>
                    <div className='cvat-job-page-list-item-dimension'>{job.dimension.toUpperCase()}</div>
                </>
            )}
        >
            <Descriptions column={1} size='small'>
                <Descriptions.Item label='Stage'>{job.stage}</Descriptions.Item>
                <Descriptions.Item label='State'>{job.state}</Descriptions.Item>
                {expanded ? (
                    <Descriptions.Item label='Size'>{job.stopFrame - job.startFrame + 1}</Descriptions.Item>
                ) : null}
                {expanded && job.assignee ? (
                    <Descriptions.Item label='Assignee'>{job.assignee.username}</Descriptions.Item>
                ) : null}
            </Descriptions>
            <Dropdown overlay={(
                <Menu onClick={(action: MenuInfo) => {
                    if (action.key === 'task') {
                        history.push(`/tasks/${job.taskId}`);
                    } else if (action.key === 'project') {
                        history.push(`/projects/${job.projectId}`);
                    } else if (action.key === 'bug_tracker') {
                        // false alarm
                        // eslint-disable-next-line security/detect-non-literal-fs-filename
                        window.open(job.bugTracker, '_blank', 'noopener noreferrer');
                    }
                }}
                >
                    <Menu.Item key='task' disabled={job.taskId === null}>Go to the task</Menu.Item>
                    <Menu.Item key='project' disabled={job.projectId === null}>Go to the project</Menu.Item>
                    <Menu.Item key='bug_tracker' disabled={!job.bugTracker}>Go to the bug tracker</Menu.Item>
                </Menu>
            )}
            >
                <MoreOutlined className='cvat-job-card-more-button'/>
            </Dropdown>
        </Card>
    );
}

export default React.memo(JobCardComponent);
